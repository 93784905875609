import { InteractionType } from "@azure/msal-browser";
import { LogLevel, TdoeAuthConfig } from "@tdoe/design-system";

const tdoeAuthConfig: TdoeAuthConfig = {
  authRequestScopes: [
    'api://b3be471a-14af-4995-9b95-baf566aba415/access_as_user'
  ],
  clientId: 'e993e70e-072e-4219-a7b6-b59a1d9c2e2c',
  instance: 'https://login.microsoftonline.com',
  loginFailedRoute: '/login-failed',
  protectedResources: [
    {
      key: 'https://ccte-api.qa.tnedu.gov/api',
      value: [
        'api://b3be471a-14af-4995-9b95-baf566aba415/access_as_user'
      ]
    },
    {
      key: 'https://graph.microsoft.com/v1.0/me',
      value: [
        'user.read'
      ]
    }
  ],
  redirectUri: 'https://ccte.qa.tnedu.gov',
  tenantId: '472445bd-2424-4e8f-b850-df7488e18b4a',
  logLevel: LogLevel.debug,
  takeOverConsoleLog: false,
  interactionType: InteractionType.Redirect,
  projectKey: '14DD9571-65BD-4E5A-8B16-E25009EF90F6',
  authEnabled: true
};


export const environment = {
  production: false,
  authConfig: tdoeAuthConfig,
  apiUrl: 'https://ccte-api.qa.tnedu.gov',
};
